import React from "react"
import loadable from '@loadable/component'
import pMinDelay from 'p-min-delay'

import MainContainer from "../components/containers/MainContainer"
import HeadData from "../data/HeadData"
import OneColumnPageLayout from "../components/layouts/OneColumnPageLayout"

const paragraph = 'font-body text-lg text-gray-900 mb-4'

const GoogleSheet = loadable(() => pMinDelay(import('../components/sections/GoogleSheetSection'), 2000), {
  fallback: <div className='italic text-gray-700'>Loading list...</div>,
})

const Membership = () => (
  <MainContainer>
    <HeadData
      title='Membership'
      description='List of Orange County Sigma Chi Alumni Chapter members.'
    />

    <OneColumnPageLayout>
      <h2 className='font-display text-4xl text-blue-900 mb-8 leading-tight'>List of Members</h2>

      <p className={paragraph}>2020 Alumni Membership:</p>

      <GoogleSheet />
    </OneColumnPageLayout>
  </MainContainer>
)

export default Membership
